import React, { Fragment, useState } from 'react'
import '../../css/header.css'
import '../../css/style.css'
import '../../css/dashboard.css'
import '../../css/plan.css'
import QnaLast from './QnaLast';
import QnaResponse from './QnaResponse';


export default function Qna() {

  const [steps, setSteps] = useState(3);

  return (


    <Fragment>
     
      <div className="innercontainer mb40">

        {steps === 3 && (<QnaLast steps={steps} setSteps={setSteps} />)}
        {steps === 4 && (<QnaResponse steps={steps} setSteps={setSteps} />)}

      </div>
    </Fragment>
  )
}
