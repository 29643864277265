import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Qna from './pages/tallyso/Qna'

export default function RouterPage() {

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Qna />}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    )
}
