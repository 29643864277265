import React, { Fragment, useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import axios from 'axios';
//import questions from './mock.json';
import SideImg from '../../img/BasicProfile.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SweetAlert from 'sweetalert2';

export default function QnaLast({ setSteps }) {

    //const [response, setResponse] = useState([]);


    const { control, register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();


    const [checked, setChecked] = useState(true);
    const handleChange = () => {
        setChecked(!checked);
    };

    // eslint-disable-next-line
    const [disableBtn, setDisableBtn] = useState(false);
    // eslint-disable-next-line
    const [utm_source, setUtmSource] = useState(false);
    // eslint-disable-next-line
    const [utm_medium, setUtmMedium] = useState(false);
    // eslint-disable-next-line
    const [utm_campaign, setUtmCampaign] = useState(false);
    // eslint-disable-next-line
    const [utm_term, setUtmTerm] = useState(false);

    useEffect(() => {

        const search = window.location.search;
        const params = new URLSearchParams(search);

        const utm_source = params.get('utm_source');
        setUtmSource(utm_source);

        const utm_medium = params.get("utm_medium");
        setUtmMedium(utm_medium);

        const utm_campaign = params.get('utm_campaign');
        setUtmCampaign(utm_campaign);

        const utm_term = params.get("utm_term");
        setUtmTerm(utm_term);

        var referrer = document.referrer;
        console.log("referrer url", referrer);

    }, []);

    const onSubmit = data => {

        const PostData = {
            "fullname": data.fullname,
            "email": data.email,
            "mobile": data.mobile,
            "utm_source": utm_source,
            "utm_medium": utm_medium,
            "utm_campaign": utm_campaign,
            "utm_term": utm_term,
            "appointment_date": startDate,
            "email_flag": 0,
            "sms_flag": 0,
            "lead_status": 0
        };


        axios.post(`https://lmslive.finnovate.in/php/temp-lead-restapi.php`, PostData)
            .then(function (response) {
                console.log(response);

                if (response.data.status === 100) {

                    SweetAlert.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Thank You. Will get back to you.',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                        }
                    }).then(function () {

                        window.location.reload();

                    });

                }
            })
            .catch(function (error) {

                SweetAlert.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Failed. Something went wrong. Retry again.',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                    }
                });

            });
    }
    const [startDate, setStartDate] = useState(new Date());

    return (
        <Fragment>


            <div className="mainwrapper">



                <div className="row formWpr mt25">
                    <div className="col-lg-8 formouter">
                        <ul className="form_swipe_tab font12 fw600">

                        </ul>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="formtitle flex_center justify_center">
                                <div>
                                    <div className="font20 fw600">Get my FinnFit Report</div>
                                    <div className="font15 color495 ">You will receive the report on email.</div>
                                </div>
                                <div className='d-none d-md-block'>

                                    <button className="bluebtn font13 fw600" type='submit' disabled={(disableBtn === true) ? true : !isDirty && !isValid}>Request Call Back
                                        <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                        </svg></span></button>
                                </div>
                            </div>

                            {/* Form */}
                            <div className="form_prnt pt25 pl35 pr35 pb35">
                                <div className="custome_form">

                                    <div className="form-row">
                                        <div className="form-group col-md-6 pr20">
                                            <label htmlFor="fullname" className="font14 fw600">Your full name <span class="colorfa8">*</span></label>
                                            <input type="text" className="form-control" id="fullname" name="fullname" autoComplete='off' {...register("fullname", { required: 'Name is required', minLength: { value: 3, message: 'Name is too short' }, maxLength: 'Name is too long' })} placeholder="Enter full name" />
                                            <span className="text-danger font11"> {errors.fullname && errors.fullname.message}</span>
                                        </div>
                                    </div>

                                    <div className="form-row">

                                        <div className="form-group col-md-6 pr20">
                                            <label htmlFor="email" className="font14 fw600">Email address</label>
                                            <input type="text" className="form-control" id="email" name="email" autoComplete='off' {...register("email", { required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } })} placeholder="Enter email address" />
                                            <span className="text-danger font11"> {errors.email && errors.email.message}</span>
                                        </div>

                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-6 pr20">
                                            <label htmlFor="mobileNumber" className="font14 fw600">Mobile number</label>
                                            <div className="input-group mb2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text fw500 font14 color212">+91</div>
                                                </div>
                                                <input type="tel" className="form-control" id="mobile" name="mobile" autoComplete='off' {...register("mobile", { required: 'Mobile Number is required', pattern: { value: /^(?:(?:\+|0{0,2})91(\s*|[-])?|[0]?)?([789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/, message: 'Invalid Mobile Number' } })} placeholder="Mobile Number" maxLength={10} />

                                            </div>
                                            <span className="text-danger font11"> {errors.mobile && errors.mobile.message}</span>
                                        </div>
                                    </div>

                                    <div className='form-row'>

                                        <div className='form-group col-md-6 pr20'>
                                            <label className='font14 fw600'>Preferred Appointment Date & Time</label>
                                            <Controller
                                                control={control}
                                                name='appointment_date'
                                                type='date'
                                                className="react-datepicker-ignore-onclickoutside"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        placeholderText='Select date'
                                                        onChange={(date) => setStartDate(date)}
                                                        showTimeSelect
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        selected={startDate}
                                                        className='react-datepicker-ignore-onclickoutside'
                                                    />
                                                )}
                                            />

                                            {/*<DatePicker
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                showTimeSelect
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                name='appointment_date'
                                                id='appointment_date'
                                            />*/}

                                        </div>
                                    </div>



                                    <div className="form-row">
                                        <div className="form-group col-md-12 pr20">
                                            <label htmlFor="authorization" className="font15 color495 " >
                                                <input type="checkbox" value="1" checked={checked} onClick={() => handleChange()}
                                                    name="authorization" {...register("authorization", { required: false })} /> I, hereby authorize Finnovate to contact me via call, WhatsApp, Email or sms to discuss further regarding my financial fitness.
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-12 pr20">
                                            <label className="font15 color495 " style={{ 'color': 'gray' }}>
                                                Your information is safe and protected by encryption.
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="saveBtn d-block d-sm-none">
                                <button type="submit" class="btn btn-primary" disabled={(disableBtn === true) ? true : !isDirty && !isValid}>Save and Continue <span class="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" stroke-width="1.30435" stroke-miterlimit="10"></path></svg></span></button>
                            </div>

                        </form>
                        {/* Form End */}

                    </div>

                    <div className="col-lg-4 qouteouter d-none d-md-block">
                        <div className="qoutetext font18 fw500 color212 pl45 pr45">
                            <div className="qoutesign">
                                &#8220;
                            </div>
                            FinnFit is a customized financial fitness program that partners with doctors so that you can become well-off and enjoy the fruits of your labour, into your winter years.
                        </div>


                        <div className="qouteimg"><img src={SideImg} alt='Completed' /></div>
                    </div>
                </div>



            </div>

            <div class="row mt50">
                <div className="col-lg-12">

                    <p className='font11'>Disclaimer:</p>

                    <p className='font11'>This financial fitness score is provided for educational and informational purposes only and is not intended to provide investment, tax, or legal advice. The score is calculated based on the information you provide and is not a guarantee of future performance or success. Your personal financial situation is unique, and the score may not reflect your true financial health. It is important to seek professional advice before making any financial decisions. The score is not a substitute for professional judgment and is subject to change without notice. The provider of this score assumes no responsibility for any errors or omissions in the information provided. By using this score, you agree to hold harmless the provider and its affiliates for any losses or damages that may result from any inaccuracies or incompleteness of the information.</p>

                    <p className='font11'> Registration granted by SEBI, membership of BASL and certification from NISM in no way
                        guarantee performance of the intermediary or provide any assurance of returns to investors</p>
                    <p className='font11'>Mutual Fund investments are subject to market risks, read all scheme related documents carefully.</p>
                    <p className='font11'>Past performance is not indicative of future returns. Please consider your specific investment
                        requirements, risk tolerance, goal, time frame, risk and reward balance and the cost associated with the
                        investment before choosing a fund, or designing a portfolio that suits your needs. Performance and returns
                        of any investment portfolio can neither be predicted<br />
                        nor guaranteed.</p>
                    <p className='font11'>SEBI RIA Registration No: INA000013518 | Type of Registration: Non-Individual | Validity of registration:
                        June 19, 2019 - Perpetual | Address: The Summit Business Park, 7th Floor, Unit No. 703, Andheri - Kurla
                        Rd, Behind Guru Nanak Petrol Pump, Opp. PVR Cinema, Andheri East, Mumbai – 400069 | Contact No. 9819022600
                        | Principal Officer details: Nehal Mota, Email : nehal.mota@finnovate.in, Phone: 9152022446 |
                        Corresponding Local SEBI Office : SEBI Office Address: Office of Investor Assistance and Education, SEBI
                        Bhavan Plot No.C4-A, G Block, Bandra Kurla Complex, Bandra (E), Mumbai - 400 051</p>
                    <p className='font11'>AMFI Registered Distributor - ARN 52128 | Platform Partner : BSE (Member code 17548) | IRDAI Corporate
                        Agency : CA0703 | CIN: U67190MH2007PTC172272 | GST No : 27AABCF1124C2ZC | Website – <a href="http://www.finnovate.in" target="_blank" rel='noreferrer'>www.finnovate.in</a> Email – <a href="mailto:hello@finnovate.in">hello@finnovate.in</a></p>

                </div>

            </div>
        </Fragment>
    )
}
